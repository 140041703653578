<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row no-gutters class="mt-0 primary--text text-h5 font-weight-light">
          <v-spacer></v-spacer>
          MORE LIKELY
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-badge
                  color="#ef9a9a"
                  content="TERMS"
                  offset-x="-5"
                  offset-y="-10"
                ></v-badge>
              </span>
            </template>
            <span
              >Remember, all data in gauges and graphs you see in the service is
              an <br />
              automation software calculations output. It is NOT a human
              operation result<br />
              and should not be seen as professional fianancial advice. How you
              will interpret<br />
              and use this data is on your own responsibility. Tip: check news
              link below.</span
            >
          </v-tooltip>
          <v-spacer></v-spacer>
        </v-row>
        <v-row no-gutters class="mt-0" style="border-top: 1px solid #dddddd">
          <v-col
            class="mt-0 font-weight-bold text-subtitle-2"
            align="left"
            style="color: #81c784"
          >
            POSITIVE
          </v-col>
          <v-col
            class="mt-0 font-weight-bold text-subtitle-2"
            align="center"
            style="color: #ffd54f"
          >
            NEUTRAL
          </v-col>
          <v-col
            class="mt-0 font-weight-bold text-subtitle-2"
            align="right"
            style="color: #ef9a9a"
          >
            NEGATIVE
          </v-col>
        </v-row>
        <v-row
          id="gauge-value"
          no-gutters
          class="mb-0 white--text"
          align="center"
          justify="center"
        >
          <v-gauge
            width="400px"
            :value="valueUpdate"
            :options="options"
          ></v-gauge>
        </v-row>
        <v-row
          no-gutters
          class="mt-0 primary--text font-weight-light"
          style="letter-spacing: 0.4em; border-bottom: 1px solid #dddddd"
        >
          <v-spacer></v-spacer>BASED ON NEWS SENTIMENT<v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VGauge from 'vgauge';
export default {
  name: 'Gauge',
  components: {
    VGauge,
  },
  props: ['ticker'],
  data() {
    return {
      value: 35,
      options: {
        minValue: 0,
        maxValue: 100,
        angle: 0,
        lineWidth: 0.25,
        radiusScale: 1,
        animationSpeed: 40,
        pointer: {
          length: 0.5,
          strokeWidth: 0.1,
          color: '#607D8B',
        },
        staticZones: [
          { strokeStyle: '#81C784', min: 0, max: 45 }, // Green
          { strokeStyle: '#FFD54F', min: 45, max: 55 }, // Yellow
          { strokeStyle: '#EF9A9A', min: 55, max: 100 }, // Red
        ],
        highDpiSupport: true,
      },
    };
  },
  computed: {
    valueUpdate() {
      return this.value;
    },
    pricesMapped() {
      return this.$store.getters.getPricesMapped;
    },
  },
  watch: {
    pricesMapped() {
      this.calculateGaugeValues();
    },
  },
  mounted() {
    this.calculateGaugeValues();
  },
  methods: {
    calculateGaugeValues() {
      const ticker = this.ticker;
      const tickerPrices = this.pricesMapped;
      if (!tickerPrices[ticker]) {
        return;
      }
      const tickerPredictedPrices = tickerPrices[ticker].predicted.value;
      const firstPrice = tickerPredictedPrices[0];
      const lastPrice = tickerPredictedPrices[tickerPredictedPrices.length - 1];
      const priceDiff = lastPrice - firstPrice;
      const priceDifPercent = (priceDiff * 100) / firstPrice;
      const coeff = ~~priceDifPercent > 100 ? 1 : 2; // Gauge normalization coefficient
      const valueRaw = (~~(priceDifPercent.toFixed(2) * coeff) + 100) / 2;
      this.value = Math.abs(valueRaw - 100);
    },
  },
};
</script>

<style scoped></style>
