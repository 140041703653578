<template>
  <v-row align="center" justify="center" class="mt-4">
    <v-col class="pa-1" cols="2" v-for="(item, idx) in assetTypes" :key="idx">
      <v-card
        :disabled="!assetAvailable(item)"
        @click.stop="setUserAsset(item)"
        :color="item.includes(userAsset) ? '#EEEEEE' : ''"
        align="right"
      >
        <v-card-title class="text-h4 font-weight-light">
          <span class="text-center" style="text-transform: capitalize">{{
            item
          }}</span>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-img
            to="/"
            position="right"
            max-width="60"
            :src="require(`@/assets/logos/${item}.png`)"
          ></v-img>
        </v-card-text>
        <v-progress-linear
          v-show="!assetAvailable(item)"
          color="accent"
          :value="assetProgress ? assetProgress[item] : 0"
        ></v-progress-linear>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Assets',
  data() {
    return {
      assetProgress: {
        stocks: 0,
        crypto: 0,
        currency: 0,
        metals: 0,
        funds: 0,
      },
    };
  },
  computed: {
    assetTypes() {
      return this.$store.getters.getAssetTypes;
    },
    assetsData() {
      return this.$store.getters.getAssetsData;
    },
    getAssetsDataCalls() {
      return this.$store.getters.getAssetsDataCalls;
    },
    userAsset() {
      return this.$store.getters.getUserAsset;
    },
    user() {
      return this.$store.getters.getUser;
    },
    userReady() {
      return this.$store.getters.isUserReady;
    },
  },
  methods: {
    assetAvailable(asset) {
      return (
        (this.assetsData &&
          Object.keys(this.assetsData).includes(asset) &&
          !this.getAssetsDataCalls[asset] &&
          !this.getAssetsDataCalls['names']) ||
        asset == 'stocks'
      );
    },
    setAssetProgress() {
      let incrementInterval, totalProgress;
      this.assetTypes.forEach((assetType) => {
        switch (true) {
          case assetType.includes('stock'):
            incrementInterval = 1000;
            totalProgress = 10000;
            break;
          case assetType.includes('crypto'):
            incrementInterval = 2000;
            totalProgress = 20000;
            break;
          case assetType.includes('currency'):
            incrementInterval = 1500;
            totalProgress = 15000;
            break;
          case assetType.includes('metals'):
            incrementInterval = 500;
            totalProgress = 5000;
            break;
          case assetType.includes('funds'):
            incrementInterval = 1500;
            totalProgress = 15000;
            break;
          default:
            incrementInterval = 1000;
            totalProgress = 10000;
            break;
        }
        let progressValue = 0;
        let intervalId = setInterval(() => {
          progressValue += totalProgress / incrementInterval;
          this.assetProgress[assetType] = progressValue;
        }, incrementInterval);
        setTimeout(() => {
          clearInterval(intervalId);
          progressValue = 100;
          this.assetProgress[assetType] = progressValue;
        }, totalProgress);
      });
    },
    setUserAsset(assetType) {
      this.$store.commit('setUserAsset', assetType);
    },
  },
  mounted() {
    this.setUserAsset('stocks');
    this.setAssetProgress();
  },
  watch: {
    user() {
      this.setUserAsset('stocks');
      this.setAssetProgress();
    },
    userReady() {
      this.setUserAsset('stocks');
      this.setAssetProgress();
    },
  },
};
</script>

<style scoped></style>
