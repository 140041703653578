<template>
  <v-container grid-list-lg>
    <span class="font-weight-light">
      <v-row class="mb-3">
        <v-col v-if="date" cols="10" sm="10" class="py-1 text-uppercase">
          <span v-if="!userReady" class="red--text">
            <span class="text-subtitle-1">
              Data for {{ date.toLocaleString() }} <br />
            </span>
            <span class="py-1 caption">Subscribe For Today's Data</span>
          </span>
          <span v-else>
            <span class="text-h4">
              Data for {{ date.toLocaleString() }} <br />
            </span>
          </span>
        </v-col>
        <v-col cols="2" sm="2" align="right">
          <v-btn
            height="40"
            elevation="2"
            color="white"
            medium
            @click.stop="showGappers()"
          >
            <v-icon size="35" color="accent">mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </span>
    <v-layout row wrap>
      <div
        class="text-h3 text--disabled font-weight-light text-center"
        v-if="
          (!loadingState && !gappers) ||
          (assetsToShow && assetsToShow.length == 0)
        "
      >
        No {{ userAsset }} assets data yet at {{ marketTime }}
      </div>
      <v-flex xs12 sm6 md6 lg4 v-for="(item, idx) in assetsToShow" :key="idx">
        <v-card :id="`card-${item.ticker}`">
          <v-card-title>
            <div>
              <a
                class="accent--text"
                target="_blank"
                :href="`https://www.google.com/search?q=$${item.ticker}-${item.symbolName}&tbm=fin`"
                >{{ item.ticker }}</a
              >
            </div>
            <v-spacer></v-spacer>
            <div class="my-3 title">
              {{
                item.symbolName.length > 25
                  ? item.symbolName.slice(0, 25) + '...'
                  : item.symbolName
              }}
            </div>
          </v-card-title>
          <v-card-text>
            <div v-if="pricesMapped">
              <v-tabs v-model="tabPressed" centered>
                <v-tabs-slider></v-tabs-slider>
                <v-tab :href="'#Gauge-' + item.ticker"> Gauge </v-tab>
                <v-tab :href="'#Chart-' + item.ticker"> Chart </v-tab>
                <v-tab-item :value="'Gauge-' + item.ticker">
                  <v-row>
                    <v-col>
                      <v-sheet min-height="400">
                        <gauge :ticker="item.ticker"></gauge>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'Chart-' + item.ticker">
                  <v-row>
                    <v-col>
                      <v-sheet min-height="400">
                        <chart :ticker="item.ticker"></chart>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </div>
            <v-row class="mt-5">
              <v-col>
                <div class="subheading">
                  {{ strings.dashboard.card.gapping_percent }}:
                  <span
                    :class="
                      getCorrectedPercent(item.gapPercent).includes('+')
                        ? `green--text`
                        : `accent--text`
                    "
                  >
                    {{ getCorrectedPercent(item.gapPercent) }}</span
                  >
                  <br />
                  {{ strings.dashboard.card.gapping_price }}:
                  {{ parseFloat(item.lastPrice).toFixed(2) }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-badge color="accent" dot inline> DCU </v-badge>
                      </span>
                    </template>
                    <span>Default Conventional Units</span>
                  </v-tooltip>
                  <br />
                  {{ strings.dashboard.card.gapping_volume }}:
                  {{ parseInt(item.volume).toLocaleString('en-US') }}
                </div>
              </v-col>
              <v-col
                v-if="news && assetsStats[item.ticker]"
                style="border-left: 1px solid #dddddd"
              >
                <a
                  class="accent--text text-decoration-underline"
                  @click.stop="
                    detailsDialogShown = true;
                    clickedCardObject = item;
                  "
                  >Check {{ assetsStats[item.ticker].totalNews }} AI &#38; NLP
                  processed news
                </a>
                <br />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-badge color="accent" dot left inline>
                        News magnitude
                      </v-badge>
                    </span>
                  </template>
                  <span
                    >Represents average news sentiment weight,<br />i.e. how
                    "bold" was news statement. 0 to ~</span
                  > </v-tooltip
                >: {{ assetsStats[item.ticker].magnutude }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <v-badge color="accent" dot left inline>
                        News coefficient
                      </v-badge>
                    </span>
                  </template>
                  <span
                    >Average news sentiment coefficient. -1 to 1</span
                  > </v-tooltip
                >: {{ assetsStats[item.ticker].coefficient }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <div v-if="userAsset.includes('crypto')">
      <!-- Positive trending coins -->
      <br />
      <v-divider></v-divider>
      <br />
      <div
        class="text-h3 text--disabled font-weight-light text-center"
        v-if="cryptoData && cryptoData.cryptoLists.cryptoListPlus.length == 0"
      >
        No positive trending crypto coins!
      </div>
      <div class="text-h3 text--disabled font-weight-light text-center" v-else>
        Long time positive trending crypto coins:
      </div>
      <br />
      <br />
      <v-layout row wrap>
        <v-flex
          xs12
          sm6
          md6
          lg4
          v-for="(item, idx) in cryptoData.cryptoLists.cryptoListPlus"
          :key="idx"
        >
          <v-card :id="`card-${item.ticker}`">
            <v-card-title>
              <div>
                <a
                  class="accent--text"
                  target="_blank"
                  :href="`https://www.google.com/search?q=$${item.ticker}-${item.symbolName}&tbm=fin`"
                  >{{ item.ticker }}</a
                >
              </div>
              <v-spacer></v-spacer>
              <div class="my-3 title">
                {{
                  item.symbolName.length > 25
                    ? item.symbolName.slice(0, 25) + '...'
                    : item.symbolName
                }}
              </div>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-5">
                <v-col>
                  <div class="subheading">
                    {{ strings.dashboard.card.gapping_percent }}:
                    <span
                      :class="
                        getCorrectedPercent(item.gapPercent).includes('+')
                          ? `green--text`
                          : `accent--text`
                      "
                    >
                      {{ getCorrectedPercent(item.gapPercent) }}</span
                    >
                    <br />
                    {{ strings.dashboard.card.gapping_price }}:
                    {{ parseFloat(item.lastPrice).toFixed(2) }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-badge color="accent" dot inline> DCU </v-badge>
                        </span>
                      </template>
                      <span>Default Conventional Units</span>
                    </v-tooltip>
                    <br />
                    {{ strings.dashboard.card.gapping_volume }}:
                    {{ parseInt(item.volume).toLocaleString('en-US') }}
                  </div>
                </v-col>
                <v-col
                  v-if="news && assetsStats[item.ticker]"
                  style="border-left: 1px solid #dddddd"
                >
                  <a
                    class="accent--text text-decoration-underline"
                    @click.stop="
                      detailsDialogShown = true;
                      clickedCardObject = item;
                    "
                    >Check {{ assetsStats[item.ticker].totalNews }} AI &#38; NLP
                    processed news
                  </a>
                  <br />
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-badge color="accent" dot left inline>
                          News magnitude
                        </v-badge>
                      </span>
                    </template>
                    <span
                      >Represents average news sentiment weight,<br />i.e. how
                      "bold" was news statement. 0 to ~</span
                    > </v-tooltip
                  >: {{ assetsStats[item.ticker].magnutude }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-badge color="accent" dot left inline>
                          News coefficient
                        </v-badge>
                      </span>
                    </template>
                    <span
                      >Average news sentiment coefficient. -1 to 1</span
                    > </v-tooltip
                  >: {{ assetsStats[item.ticker].coefficient }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <!-- Negative trending coins -->
      <br />
      <v-divider></v-divider>
      <br />
      <div
        class="text-h3 text--disabled font-weight-light text-center"
        v-if="cryptoData && cryptoData.cryptoLists.cryptoListMinus.length == 0"
      >
        No negative trending crypto coins!
      </div>
      <div class="text-h3 text--disabled font-weight-light text-center" v-else>
        Long time negative trending crypto coins:
      </div>
      <br />
      <br />
      <v-layout row wrap>
        <v-flex
          xs12
          sm6
          md6
          lg4
          v-for="(item, idx) in cryptoData.cryptoLists.cryptoListMinus"
          :key="idx"
        >
          <v-card :id="`card-${item.ticker}`">
            <v-card-title>
              <div>
                <a
                  class="accent--text"
                  target="_blank"
                  :href="`https://www.google.com/search?q=$${item.ticker}-${item.symbolName}&tbm=fin`"
                  >{{ item.ticker }}</a
                >
              </div>
              <v-spacer></v-spacer>
              <div class="my-3 title">
                {{
                  item.symbolName.length > 25
                    ? item.symbolName.slice(0, 25) + '...'
                    : item.symbolName
                }}
              </div>
            </v-card-title>
            <v-card-text>
              <v-row class="mt-5">
                <v-col>
                  <div class="subheading">
                    {{ strings.dashboard.card.gapping_percent }}:
                    <span
                      :class="
                        getCorrectedPercent(item.gapPercent).includes('+')
                          ? `green--text`
                          : `accent--text`
                      "
                    >
                      {{ getCorrectedPercent(item.gapPercent) }}</span
                    >
                    <br />
                    {{ strings.dashboard.card.gapping_price }}:
                    {{ parseFloat(item.lastPrice).toFixed(2) }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-badge color="accent" dot inline> DCU </v-badge>
                        </span>
                      </template>
                      <span>Default Conventional Units</span>
                    </v-tooltip>
                    <br />
                    {{ strings.dashboard.card.gapping_volume }}:
                    {{ parseInt(item.volume).toLocaleString('en-US') }}
                  </div>
                </v-col>
                <v-col
                  v-if="news && assetsStats[item.ticker]"
                  style="border-left: 1px solid #dddddd"
                >
                  <a
                    class="accent--text text-decoration-underline"
                    @click.stop="
                      detailsDialogShown = true;
                      clickedCardObject = item;
                    "
                    >Check {{ assetsStats[item.ticker].totalNews }} AI &#38; NLP
                    processed news
                  </a>
                  <br />
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-badge color="accent" dot left inline>
                          News magnitude
                        </v-badge>
                      </span>
                    </template>
                    <span
                      >Represents average news sentiment weight,<br />i.e. how
                      "bold" was news statement. 0 to ~</span
                    > </v-tooltip
                  >: {{ assetsStats[item.ticker].magnutude }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-badge color="accent" dot left inline>
                          News coefficient
                        </v-badge>
                      </span>
                    </template>
                    <span
                      >Average news sentiment coefficient. -1 to 1</span
                    > </v-tooltip
                  >: {{ assetsStats[item.ticker].coefficient }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </div>

    <br />
    <br />

    <AssetDetails
      v-if="clickedCardObject && pricesMapped && news"
      :clickedCardObject.sync="clickedCardObject"
      :detailsDialogShown.sync="detailsDialogShown"
    />
    <v-overlay :value="loadingState">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import Chart from '@/utils/chart';
import Gauge from './Gauge';
import AssetDetails from '@/components/modals/AssetDetails';
export default {
  name: 'Graphs',
  components: {
    Chart,
    Gauge,
    AssetDetails,
  },
  data() {
    return {
      tab: null,
      assetsStats: {},
      clickedCardObject: null,
      detailsDialogShown: false,
      weekGappersDates: null,
    };
  },
  computed: {
    tabPressed: {
      get() {
        return this.tab;
      },
      set(tabName) {
        return tabName;
      },
    },
    loadingState() {
      return this.$store.getters.isLoading;
    },
    date() {
      return this.$store.getters.getDate;
    },
    localTime() {
      return this.$store.getters.getLocalTime;
    },
    userAsset() {
      return this.$store.getters.getUserAsset;
    },
    assetsNames() {
      return this.$store.getters.getAssetsNames;
    },
    assetsData() {
      return this.$store.getters.getAssetsData;
    },
    cryptoData() {
      return this.$store.getters.getCryptoData;
    },
    gappers() {
      return this.$store.getters.getGappers;
    },
    gappersWeek() {
      return this.$store.getters.getWeekGappers;
    },
    gappersNews() {
      return this.$store.getters.getNewsGappers;
    },
    assetsToShow() {
      if (this.userAsset.includes('stocks')) {
        if (this.gappers && this.gappersNews) {
          return this.gappers.filter((object) => {
            return this.gappersNews.includes(object.ticker);
          });
        } else {
          return this.gappers;
        }
      } else {
        return this.assetsData[this.userAsset];
      }
    },
    news() {
      return this.$store.getters.getNews;
    },
    user() {
      return this.$store.getters.getUser;
    },
    userMarket() {
      return this.$store.getters.getUserMarket;
    },
    marketSettings() {
      return this.$store.getters.getMarketSettings;
    },
    marketTime() {
      const date = this.localTime.toLocaleString(undefined, {
        timeZone: this.marketSettings.timeLocale,
      });
      const time = date.split(', ')[1].split(' ')[0];
      const amfm = date.split(', ')[1].split(' ')[1];
      return time.split(':')[0] + ':' + time.split(':')[1] + ' ' + amfm;
    },
    prices() {
      return this.$store.getters.getPrices;
    },
    pricesMapped() {
      return this.$store.getters.getPricesMapped;
    },
    strings() {
      return this.$store.getters.getStrings;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    isVerified() {
      return this.$store.getters.isUserVerified;
    },
    userReady() {
      return this.$store.getters.isUserReady;
    },
  },
  methods: {
    setAssetNewsStats() {
      this.assetsStats = {};
      const assetsObjects = this.assetsToShow;
      if (!assetsObjects) return;
      assetsObjects.forEach((assetsObeject) => {
        this.setAssetNewsStat(assetsObeject.ticker);
      });
    },
    setAssetNewsStat(ticker) {
      let stat = {
        totalNews: 0,
        magnutude: 0,
        coefficient: 0,
      };
      let magnutudes = [];
      let coefficients = [];

      if (this.userAsset.includes('stocks')) {
        let tickerNews = this.news.filter((object) => {
          return object.tickerName.includes(ticker);
        })[0];
        tickerNews = tickerNews.data;
        stat.totalNews = tickerNews.length;

        tickerNews.map((newsObject) => {
          magnutudes.push(parseFloat(newsObject.mood.magnitude));
          coefficients.push(parseFloat(newsObject.mood.coefficient));
        });
        if (magnutudes.length == 0 || coefficients.length == 0) {
          return stat;
        }

        stat.magnutude = (
          magnutudes.reduce((a, b) => a + b) / magnutudes.length
        ).toFixed(2);
        stat.coefficient = (
          coefficients.reduce((a, b) => a + b) / coefficients.length
        ).toFixed(2);
      } else {
        const assetObjects = this.assetsData[this.userAsset];
        const assetObject = assetObjects.filter((assetObject) => {
          return assetObject.ticker.includes(ticker);
        })[0];
        const dateString = this.date.toLocaleString();

        // Get news from current date, backend returns for two and more dates
        const dateNewsObject = assetObject.news.filter((newsObject) => {
          return newsObject.date.includes(dateString);
        })[0];

        try {
          stat.totalNews = dateNewsObject.data[ticker].length;
        } catch (error) {
          console.log(
            `\n\nAssigning total news for date "${dateString}"stat went wrong for asset object: - "${JSON.stringify(
              dateNewsObject,
              null,
              2
            )}"\n\n`
          );
        }

        // Get mood from current date, backend returns for two and more dates
        const dateMoodObject = assetObject.mood.filter((moodObject) => {
          return moodObject.date.includes(dateString);
        })[0];

        try {
          stat.magnutude = dateMoodObject.data.magnitude.toFixed(2);
          stat.coefficient = dateMoodObject.data.coefficient.toFixed(2);
        } catch (error) {
          console.log(
            `\n\nAssigning mood for date "${dateString}"stat went wrong for asset object: - "${JSON.stringify(
              dateNewsObject,
              null,
              2
            )}"\n\n`
          );
        }
      }

      this.assetsStats = { ...this.assetsStats, ...{ [ticker]: stat } };
    },
    getCorrectedPercent(percentString) {
      var cleanString = percentString
        .replace('+', '')
        .replace('-', '')
        .replace('%', '');
      let float = parseFloat(cleanString).toFixed(2);
      return percentString.includes('+')
        ? '+' + float + '%'
        : percentString.includes('-')
        ? '-' + float + '%'
        : float + '%';
    },
    showGappers() {
      this.weekGappersDates = null;
      this.$store.dispatch('fetchGappers');
      this.$store.dispatch('fetchAssetsNames');
    },
    showPrices() {
      this.$store.dispatch('fetchPrices');
    },
    showPricesChart() {
      this.$store.dispatch('mapChartPrices');
    },
    setWeekGappersDays() {
      this.weekGappersDates = this.gappersWeek.map((record) => {
        return record.id;
      });
    },
    dateChange(date) {
      this.$store.dispatch('switchGappers', { date });
    },
    fetchNews() {
      this.$store.dispatch('fetchNews');
    },
  },
  mounted() {
    this.showGappers();
  },
  watch: {
    userMarket() {
      this.showGappers();
    },
    user() {
      this.showGappers();
    },
    userReady() {
      this.showGappers();
    },
    news() {
      this.news && this.setAssetNewsStats();
    },
    userAsset() {
      // Error: when getting assets news, call is for yesterday data, but latest news records in db are todays ones.
      this.setAssetNewsStats();
      this.showPricesChart();
    },
    assetsNames() {
      if (this.assetsNames) {
        this.$store.dispatch('fetchAssetsData');
        this.$store.dispatch('fetchCryptoData');
      }
    },
    gappers() {
      if (this.gappers && this.gappers.length > 0) {
        this.showPrices();
        this.setWeekGappersDays();
        this.fetchNews();
      }
    },
    prices() {
      this.showPricesChart();
    },
  },
};
</script>

<style scoped></style>
