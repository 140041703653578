var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-lg":""}},[_c('span',{staticClass:"font-weight-light"},[_c('v-row',{staticClass:"mb-3"},[(_vm.date)?_c('v-col',{staticClass:"py-1 text-uppercase",attrs:{"cols":"10","sm":"10"}},[(!_vm.userReady)?_c('span',{staticClass:"red--text"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" Data for "+_vm._s(_vm.date.toLocaleString())+" "),_c('br')]),_c('span',{staticClass:"py-1 caption"},[_vm._v("Subscribe For Today's Data")])]):_c('span',[_c('span',{staticClass:"text-h4"},[_vm._v(" Data for "+_vm._s(_vm.date.toLocaleString())+" "),_c('br')])])]):_vm._e(),_c('v-col',{attrs:{"cols":"2","sm":"2","align":"right"}},[_c('v-btn',{attrs:{"height":"40","elevation":"2","color":"white","medium":""},on:{"click":function($event){$event.stopPropagation();return _vm.showGappers()}}},[_c('v-icon',{attrs:{"size":"35","color":"accent"}},[_vm._v("mdi-refresh")])],1)],1)],1)],1),_c('v-layout',{attrs:{"row":"","wrap":""}},[(
        (!_vm.loadingState && !_vm.gappers) ||
        (_vm.assetsToShow && _vm.assetsToShow.length == 0)
      )?_c('div',{staticClass:"text-h3 text--disabled font-weight-light text-center"},[_vm._v(" No "+_vm._s(_vm.userAsset)+" assets data yet at "+_vm._s(_vm.marketTime)+" ")]):_vm._e(),_vm._l((_vm.assetsToShow),function(item,idx){return _c('v-flex',{key:idx,attrs:{"xs12":"","sm6":"","md6":"","lg4":""}},[_c('v-card',{attrs:{"id":("card-" + (item.ticker))}},[_c('v-card-title',[_c('div',[_c('a',{staticClass:"accent--text",attrs:{"target":"_blank","href":("https://www.google.com/search?q=$" + (item.ticker) + "-" + (item.symbolName) + "&tbm=fin")}},[_vm._v(_vm._s(item.ticker))])]),_c('v-spacer'),_c('div',{staticClass:"my-3 title"},[_vm._v(" "+_vm._s(item.symbolName.length > 25 ? item.symbolName.slice(0, 25) + '...' : item.symbolName)+" ")])],1),_c('v-card-text',[(_vm.pricesMapped)?_c('div',[_c('v-tabs',{attrs:{"centered":""},model:{value:(_vm.tabPressed),callback:function ($$v) {_vm.tabPressed=$$v},expression:"tabPressed"}},[_c('v-tabs-slider'),_c('v-tab',{attrs:{"href":'#Gauge-' + item.ticker}},[_vm._v(" Gauge ")]),_c('v-tab',{attrs:{"href":'#Chart-' + item.ticker}},[_vm._v(" Chart ")]),_c('v-tab-item',{attrs:{"value":'Gauge-' + item.ticker}},[_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"min-height":"400"}},[_c('gauge',{attrs:{"ticker":item.ticker}})],1)],1)],1)],1),_c('v-tab-item',{attrs:{"value":'Chart-' + item.ticker}},[_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"min-height":"400"}},[_c('chart',{attrs:{"ticker":item.ticker}})],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_percent)+": "),_c('span',{class:_vm.getCorrectedPercent(item.gapPercent).includes('+')
                      ? "green--text"
                      : "accent--text"},[_vm._v(" "+_vm._s(_vm.getCorrectedPercent(item.gapPercent)))]),_c('br'),_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_price)+": "+_vm._s(parseFloat(item.lastPrice).toFixed(2))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","inline":""}},[_vm._v(" DCU ")])],1)]}}],null,true)},[_c('span',[_vm._v("Default Conventional Units")])]),_c('br'),_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_volume)+": "+_vm._s(parseInt(item.volume).toLocaleString('en-US'))+" ")],1)]),(_vm.news && _vm.assetsStats[item.ticker])?_c('v-col',{staticStyle:{"border-left":"1px solid #dddddd"}},[_c('a',{staticClass:"accent--text text-decoration-underline",on:{"click":function($event){$event.stopPropagation();_vm.detailsDialogShown = true;
                  _vm.clickedCardObject = item;}}},[_vm._v("Check "+_vm._s(_vm.assetsStats[item.ticker].totalNews)+" AI & NLP processed news ")]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","left":"","inline":""}},[_vm._v(" News magnitude ")])],1)]}}],null,true)},[_c('span',[_vm._v("Represents average news sentiment weight,"),_c('br'),_vm._v("i.e. how \"bold\" was news statement. 0 to ~")])]),_vm._v(": "+_vm._s(_vm.assetsStats[item.ticker].magnutude)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","left":"","inline":""}},[_vm._v(" News coefficient ")])],1)]}}],null,true)},[_c('span',[_vm._v("Average news sentiment coefficient. -1 to 1")])]),_vm._v(": "+_vm._s(_vm.assetsStats[item.ticker].coefficient)+" ")],1):_vm._e()],1)],1)],1)],1)})],2),(_vm.userAsset.includes('crypto'))?_c('div',[_c('br'),_c('v-divider'),_c('br'),(_vm.cryptoData && _vm.cryptoData.cryptoLists.cryptoListPlus.length == 0)?_c('div',{staticClass:"text-h3 text--disabled font-weight-light text-center"},[_vm._v(" No positive trending crypto coins! ")]):_c('div',{staticClass:"text-h3 text--disabled font-weight-light text-center"},[_vm._v(" Long time positive trending crypto coins: ")]),_c('br'),_c('br'),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.cryptoData.cryptoLists.cryptoListPlus),function(item,idx){return _c('v-flex',{key:idx,attrs:{"xs12":"","sm6":"","md6":"","lg4":""}},[_c('v-card',{attrs:{"id":("card-" + (item.ticker))}},[_c('v-card-title',[_c('div',[_c('a',{staticClass:"accent--text",attrs:{"target":"_blank","href":("https://www.google.com/search?q=$" + (item.ticker) + "-" + (item.symbolName) + "&tbm=fin")}},[_vm._v(_vm._s(item.ticker))])]),_c('v-spacer'),_c('div',{staticClass:"my-3 title"},[_vm._v(" "+_vm._s(item.symbolName.length > 25 ? item.symbolName.slice(0, 25) + '...' : item.symbolName)+" ")])],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_percent)+": "),_c('span',{class:_vm.getCorrectedPercent(item.gapPercent).includes('+')
                        ? "green--text"
                        : "accent--text"},[_vm._v(" "+_vm._s(_vm.getCorrectedPercent(item.gapPercent)))]),_c('br'),_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_price)+": "+_vm._s(parseFloat(item.lastPrice).toFixed(2))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","inline":""}},[_vm._v(" DCU ")])],1)]}}],null,true)},[_c('span',[_vm._v("Default Conventional Units")])]),_c('br'),_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_volume)+": "+_vm._s(parseInt(item.volume).toLocaleString('en-US'))+" ")],1)]),(_vm.news && _vm.assetsStats[item.ticker])?_c('v-col',{staticStyle:{"border-left":"1px solid #dddddd"}},[_c('a',{staticClass:"accent--text text-decoration-underline",on:{"click":function($event){$event.stopPropagation();_vm.detailsDialogShown = true;
                    _vm.clickedCardObject = item;}}},[_vm._v("Check "+_vm._s(_vm.assetsStats[item.ticker].totalNews)+" AI & NLP processed news ")]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","left":"","inline":""}},[_vm._v(" News magnitude ")])],1)]}}],null,true)},[_c('span',[_vm._v("Represents average news sentiment weight,"),_c('br'),_vm._v("i.e. how \"bold\" was news statement. 0 to ~")])]),_vm._v(": "+_vm._s(_vm.assetsStats[item.ticker].magnutude)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","left":"","inline":""}},[_vm._v(" News coefficient ")])],1)]}}],null,true)},[_c('span',[_vm._v("Average news sentiment coefficient. -1 to 1")])]),_vm._v(": "+_vm._s(_vm.assetsStats[item.ticker].coefficient)+" ")],1):_vm._e()],1)],1)],1)],1)}),1),_c('br'),_c('v-divider'),_c('br'),(_vm.cryptoData && _vm.cryptoData.cryptoLists.cryptoListMinus.length == 0)?_c('div',{staticClass:"text-h3 text--disabled font-weight-light text-center"},[_vm._v(" No negative trending crypto coins! ")]):_c('div',{staticClass:"text-h3 text--disabled font-weight-light text-center"},[_vm._v(" Long time negative trending crypto coins: ")]),_c('br'),_c('br'),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.cryptoData.cryptoLists.cryptoListMinus),function(item,idx){return _c('v-flex',{key:idx,attrs:{"xs12":"","sm6":"","md6":"","lg4":""}},[_c('v-card',{attrs:{"id":("card-" + (item.ticker))}},[_c('v-card-title',[_c('div',[_c('a',{staticClass:"accent--text",attrs:{"target":"_blank","href":("https://www.google.com/search?q=$" + (item.ticker) + "-" + (item.symbolName) + "&tbm=fin")}},[_vm._v(_vm._s(item.ticker))])]),_c('v-spacer'),_c('div',{staticClass:"my-3 title"},[_vm._v(" "+_vm._s(item.symbolName.length > 25 ? item.symbolName.slice(0, 25) + '...' : item.symbolName)+" ")])],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_percent)+": "),_c('span',{class:_vm.getCorrectedPercent(item.gapPercent).includes('+')
                        ? "green--text"
                        : "accent--text"},[_vm._v(" "+_vm._s(_vm.getCorrectedPercent(item.gapPercent)))]),_c('br'),_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_price)+": "+_vm._s(parseFloat(item.lastPrice).toFixed(2))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","inline":""}},[_vm._v(" DCU ")])],1)]}}],null,true)},[_c('span',[_vm._v("Default Conventional Units")])]),_c('br'),_vm._v(" "+_vm._s(_vm.strings.dashboard.card.gapping_volume)+": "+_vm._s(parseInt(item.volume).toLocaleString('en-US'))+" ")],1)]),(_vm.news && _vm.assetsStats[item.ticker])?_c('v-col',{staticStyle:{"border-left":"1px solid #dddddd"}},[_c('a',{staticClass:"accent--text text-decoration-underline",on:{"click":function($event){$event.stopPropagation();_vm.detailsDialogShown = true;
                    _vm.clickedCardObject = item;}}},[_vm._v("Check "+_vm._s(_vm.assetsStats[item.ticker].totalNews)+" AI & NLP processed news ")]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","left":"","inline":""}},[_vm._v(" News magnitude ")])],1)]}}],null,true)},[_c('span',[_vm._v("Represents average news sentiment weight,"),_c('br'),_vm._v("i.e. how \"bold\" was news statement. 0 to ~")])]),_vm._v(": "+_vm._s(_vm.assetsStats[item.ticker].magnutude)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-badge',{attrs:{"color":"accent","dot":"","left":"","inline":""}},[_vm._v(" News coefficient ")])],1)]}}],null,true)},[_c('span',[_vm._v("Average news sentiment coefficient. -1 to 1")])]),_vm._v(": "+_vm._s(_vm.assetsStats[item.ticker].coefficient)+" ")],1):_vm._e()],1)],1)],1)],1)}),1)],1):_vm._e(),_c('br'),_c('br'),(_vm.clickedCardObject && _vm.pricesMapped && _vm.news)?_c('AssetDetails',{attrs:{"clickedCardObject":_vm.clickedCardObject,"detailsDialogShown":_vm.detailsDialogShown},on:{"update:clickedCardObject":function($event){_vm.clickedCardObject=$event},"update:clicked-card-object":function($event){_vm.clickedCardObject=$event},"update:detailsDialogShown":function($event){_vm.detailsDialogShown=$event},"update:details-dialog-shown":function($event){_vm.detailsDialogShown=$event}}}):_vm._e(),_c('v-overlay',{attrs:{"value":_vm.loadingState}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }