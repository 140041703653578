<template>
  <v-dialog v-model="detailsDialogShown" persistent max-width="600px">
    <v-card min-height="300">
      <v-card-title>
        <v-row class="headline">
          <v-col cols="3">
            <a
              target="_blank"
              :href="`https://www.google.com/search?q=$${tickerName}-${tickerCompany}&tbm=fin`"
              >{{ tickerName }}</a
            >
          </v-col>
          <v-col cols="8">
            {{ tickerCompany }}
          </v-col>
          <v-col cols="1  "
            ><v-icon color="red" @click.stop="closeDialog"
              >mdi-close-circle</v-icon
            ></v-col
          >
        </v-row>
      </v-card-title>
      <v-card-text v-show="!tickerNews">
        <br /><br /><br /><br /><br /><br /><br /><br /><br />
      </v-card-text>
      <v-card-text xs12 v-for="(item, idx) in tickerNews" :key="idx">
        <hr />
        <br />
        <a target="_blank" :href="item.url">{{ item.author }}</a>
        <br />
        <br />
        {{ strings.modals.news_details.magnitude }}:
        {{ item.mood.magnitude.toFixed(2) }}
        <br />
        {{ strings.modals.news_details.coefficient }}:
        {{ item.mood.coefficient.toFixed(2) }}
        <br />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.stop="closeDialog">{{
          strings.common.close
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssetDetails',
  props: {
    clickedCardObject: {
      type: Object,
      required: true,
      default: null,
    },
    detailsDialogShown: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      tickerName: this.clickedCardObject.ticker,
      tickerCompany: this.clickedCardObject.symbolName,
      tickerObject: this.clickedCardObject,
      tickerNews: null,
      dialogShown: this.detailsDialogShown,
    };
  },
  computed: {
    date() {
      return this.$store.getters.getDate;
    },
    userAsset() {
      return this.$store.getters.getUserAsset;
    },
    news() {
      return this.$store.getters.getNews;
    },
    strings() {
      return this.$store.getters.getStrings;
    },
  },
  methods: {
    closeDialog() {
      this.tickerObject = null;
      this.$emit('update:clickedCardObject', this.tickerObject);
      this.dialogShown = false;
      this.$emit('update:detailsDialogShown', this.dialogShown);
    },
    getTickerNews() {
      if (this.userAsset.includes('stocks')) {
        this.tickerNews = this.news.filter((object) => {
          return object.tickerName.includes(this.tickerName);
        })[0].data;
      } else {
        const dateString = this.date.toLocaleString();
        const dateNewsObject = this.clickedCardObject.news.filter(
          (newsObject) => {
            return newsObject.date.includes(dateString);
          }
        )[0];

        this.tickerNews = dateNewsObject.data[this.tickerName];
      }
    },
  },
  mounted() {
    this.getTickerNews();
  },
};
</script>

<style scoped></style>
