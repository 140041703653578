<template>
  <v-container fluid>
    <v-row justify-center>
      <v-col cols="12">
        <assets></assets>
      </v-col>
    </v-row>
    <v-row justify-center>
      <v-col cols="12">
        <graphs></graphs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Assets from '@/components/dashboard/Assets';
import Graphs from '@/components/dashboard/Graphs';

export default {
  name: 'Dashboard',
  components: {
    Assets,
    Graphs,
  },
};
</script>

<style scoped></style>
