import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  props: ['ticker'],
  data() {
    return {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
          labels: {
            boxWidth: 7,
            usePointStyle: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    pricesMapped() {
      return this.$store.getters.getPricesMapped;
    },
  },
  mounted() {
    this.showChart();
  },
  watch: {
    pricesMapped() {
      this.showChart();
    },
  },
  methods: {
    showChart() {
      const ticker = this.ticker;
      const tickerPrices = this.pricesMapped;
      if (!tickerPrices[ticker]) {
        return;
      }
      this.chartData = {
        labels: tickerPrices[ticker].actual.labels,
        datasets: [
          {
            label: 'Actual',
            backgroundColor: 'green',
            fill: false,
            data: tickerPrices[ticker].actual.value,
          },
          {
            label: 'Forecast',
            backgroundColor: 'blue',
            fill: false,
            data: tickerPrices[ticker].predicted.value,
          },
        ],
      };
      this.renderChart(this.chartData, this.options);
    },
  },
};
